import Main from './routes';


function App() {
  return (
      <Main />
  );
}


export default App;